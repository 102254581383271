import { twJoin } from '@troon/tailwind-preset/merge';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	floating?: boolean;
}>;

export function ProductCard(props: Props) {
	return (
		<div
			class={twJoin(
				'flex size-full flex-col gap-6 rounded border border-neutral bg-white px-6 py-8 sm:px-8',
				props.floating && 'shadow-md',
			)}
		>
			{props.children}
		</div>
	);
}
