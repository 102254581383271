import { createAsync } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';
import { ActivityIndicator, Button, Link, ProductCard } from '@troon/ui';
import { IconArrowRightMd, IconCheck } from '@troon/icons';
import { getAccessProducts } from '../providers/card';

type Props = {
	floating?: boolean;
	redirect?: string;
};

export function AccessProducts(props: Props) {
	const data = createAsync(() => getAccessProducts({}), { deferStream: true });

	return (
		<Suspense fallback={<ActivityIndicator />}>
			<Show when={data()?.products}>
				{(products) => (
					<ul class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-6">
						<For each={products()}>
							{(card) => (
								<li class="shrink grow">
									<ProductCard floating={props.floating}>
										<h2 class="text-xl font-semibold">{card.subscriptionName}</h2>
										<p class="mb-2 text-3xl font-semibold">
											<span class="text-5xl">{card.amount.displayValue}</span>/yr
										</p>

										<div class="flex flex-col gap-4 border-t border-neutral pt-8">
											<p>Includes:</p>
											<ul class="flex flex-col gap-3">
												<For each={card.valueProps}>
													{(value) => (
														<li class="flex flex-row items-center gap-2">
															<IconCheck class="shrink-0 text-xl text-brand" />
															{value}
														</li>
													)}
												</For>
											</ul>
										</div>
										<div class="flex h-full grow flex-col justify-end">
											<div>
												<Button
													as={Link}
													rel="nofollow"
													href={`/access/checkout/${card.id}`}
													state={{ redirect: props.redirect }}
													appearance={card.isPopular ? 'primary' : 'secondary'}
												>
													<span>
														Select<span class="sr-only"> the {card.subscriptionName}</span> and Continue
													</span>{' '}
													<IconArrowRightMd />
												</Button>
											</div>
										</div>
									</ProductCard>
								</li>
							)}
						</For>
					</ul>
				)}
			</Show>
		</Suspense>
	);
}
